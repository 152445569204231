import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import EmblaCarouselReact from "embla-carousel-react";
import styled from "styled-components";
import zach from "../img/zach-clarke-hex.png";
import jason from "../img/jason-stone-hex.png";
import ari from "../img/ari-luks-hex.png";
import ian from "../img/ian-deason-hex.png";
import lisa from "../img/lisa-lambert-hex.png";
import nathan from "../img/nathan-ruff-hex.png";
import patriots from "../img/patriots-logo-color.png";
import via from "../img/via-logo-color.png";
import uas from "../img/uasidekick-logo.png";
import jet from "../img/jetblue-logo-color.png";
import castle from "../img/white-castle-logo.png";
import grid from "../img/logo-national-grid.png";
import arrow from "../img/arrow.svg";
import avia from "../img/thumb-aviation-api.png";
import constr from "../img/thumb-construction-api.png";
import outdoor from "../img/thumb-outdoor-api.png";
import energy from "../img/thumb-energy-api.png";
import drones from "../img/thumb-drones-api.png";
import demand from "../img/thumb-ondemand-api.png";
import { Image } from "../styles/Styles";

const industries = [
  {
    name: "航空",
    text:
      "このツールは、日々空港リーダーたちが直面している決断しなければいけないプレッシャーをよく理解してデザインされています。",
    person: "イアン・デーソン氏、SVPカスタマーエクスペリエンス、JetBlue",
    portrait: ian,
    img: avia,
    logo: jet,
    storyPath: "/customer-stories/jetblue/"
  },
  {
    name: "建設",
    text:
      "私たちの大きなチャレンジは、どの天気予報でも天気は良好と認識し、スタッフを送りだした後に、突然どこからともなく雷を伴った嵐がやってくるような時。今では、アラートシステムのお陰で、事前に自動で知ることができるようになりました。",
    person: "ザック・クラーク氏、Director of Residential Operations、White Castle Roofing",
    portrait: zach,
    img: constr,
    logo: castle,
    storyPath: null
  },
  {
    name: "スポーツ＆アウトドア",
    text:
      "もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。",
    person:
      "ジェイソン・ストーン氏、Senior Director of Operations、Kraft Sports and Entertainment",
    portrait: jason,
    img: outdoor,
    logo: patriots,
    storyPath:
      "/customer-stories/gillette-stadium-and-the-new-england-patriots/"
  },
  {
    name: "エネルギー",
    text:
      "天候は私たちにとって危機管理、嵐への対応や復興の為に大変重要であり、エリアにおける気象予報の正確な情報を手に入れることで、予測の向上とコスト削減が可能になります。",
    person:
      "リサ・ランバート氏、CTO and Senior Vice President of National Grid",
    portrait: lisa,
    img: energy,
    logo: grid,
    storyPath: null
  },
  {
    name: "ドローン",
    text: `UASidekickの目標は、飛行中の操縦士の安全を確保すること。tomorrow.ioのMicro Weatherソリューションのような、次世代ツールを提供することはUAS産業を前進させるために欠かせないことです。`,
    person: "ナタン・ラフ氏、 UASidekick CEO",
    portrait: nathan,
    img: drones,
    logo: uas,
    storyPath: null
  },
  {
    name: "オンデマンド",
    text:
      "tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。",
    person: "アリ・ラックス氏、Operations, Via",
    portrait: ari,
    img: demand,
    logo: via,
    storyPath: "/customer-stories/via-microweather-case-study/"
  }
];

export default () => {

  const [selected, setSelected] = useState(0);
  const [embla, setEmbla] = useState(null);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelected(embla.selectedScrollSnap);
      });
    }
  }, [embla]);

  const onSelect = i => {
    embla.scrollTo(i);
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center fs-lg mb-3 mb-lg-4">
        {industries.map((item, i) => (
          <button
            onClick={() => onSelect(i)}
            key={i}
            className={`btn btn-link fs-lg px-1 px-md-2 fw-800 ${
              selected === i ? "text-black" : "text-muted"
            }`}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="position-relative">
        <Arrow src={arrow} onClick={() => embla.scrollPrev()} alt=""/>
        <Arrow right src={arrow} onClick={() => embla.scrollNext()} alt=""/>
        <EmblaCarouselReact emblaRef={setEmbla} options={{ loop: true }}>
          <div className="d-flex mb-4">
            {industries.map((industry, index) => (
              <div key={index} style={{ flex: "0 0 100%" }}>
                <div className="row no-gutters align-items-center justify-content-center">
                  <div className="col-lg-5">
                    {industry.text && (
                      <QuoteWrap>
                        <p>{industry.text}</p>
                        <div className="media align-items-start">
                          {industry.portrait && (
                            <img
                              height={80}
                              src={industry.portrait}
                              className="mr-3"
                              alt={industry.person}
                            />
                          )}
                          <div className="media-body">
                            <div
                              className="mb-2"
                              dangerouslySetInnerHTML={{
                                __html: industry.person
                              }}
                            />
                            <img height={28} src={industry.logo} alt=""/>
                          </div>
                        </div>
                        {industry.storyPath && (
                          <div className="py-4">
                            <Link
                              className="btn btn-outline-primary"
                              to={industry.storyPath}
                            >
                              もっと詳しく
                            </Link>
                          </div>
                        )}
                      </QuoteWrap>
                    )}
                  </div>
                  <div className="col-lg-6 d-none d-lg-block">
                    <Image src={industry.img} alt={industry.name}/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </EmblaCarouselReact>
      </div>
    </>
  );
};

const QuoteWrap = styled.div`
  padding-left: 1.5rem;
  padding-right: 1rem;
  p {
    font-size: 1.25rem;
    color: #6c757d;
    margin-bottom: 1.5rem;
    @media only screen and (min-width: 769px) {
      font-size: 1.5rem;
    }
  }
  .media-body {
    font-size: 0.85rem;
    @media only screen and (min-width: 769px) {
      font-size: 1rem;
    }
  }
`;

const Arrow = styled.img`
  position: absolute;
  top: 40%;
  left: ${props => (props.right ? "auto" : "-20px")};
  right: ${props => (props.right ? "-20px" : "auto")};
  transform: ${props => (props.right ? "rotate(180deg)" : "unset")};
  z-index: 1;
  cursor: pointer;
`;
