import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import Clients from "../components/Homepage/Clients";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { Container, Box, T2, T3, T4, Lead, Image, Button, Color } from "../styles/Styles";
import IndustriesCarouselApi from "../components/IndustriesCarouselApi";
import ComparingTable from "../components/ComparingTable";
import ApiTimeline from "../components/ApiTimeline";
import share from "../img/share-icon.svg";
import globe from "../img/glob-icon.svg";
import meter from "../img/meter-icon.svg";
import done from "../img/done-double-icon.svg";
import allone from "../img/all-in-one-icon.svg";
import code from "../img/code-icon.svg";
import smbg1 from "../img/api-bg-sm-1.svg";
import smbg2 from "../img/api-bg-sm-2.svg";
import smbg3 from "../img/api-bg-sm-3.svg";
import smbg4 from "../img/api-bg-sm-4.svg";
import smbg5 from "../img/api-bg-sm-5.svg";
import smbg6 from "../img/api-bg-sm-6.svg";
import smbg7 from "../img/api-bg-sm-7.svg";
import smbg8 from "../img/api-bg-sm-8.svg";
import smbg9 from "../img/api-bg-sm-9.svg";
import img1 from "../img/api-page-1.png";
import img2 from "../img/api-page-2.png";
import img3 from "../img/api-page-3.png";
import book from "../img/book-icon.png";
import rocket from "../img/icon-rocket-cloud.svg";
import building from "../img/icon-building.svg";
import theme from "../styles/theme";

const features = [
  {
    title: "もっとも正確な情報の入手",
    text: `弊社のWeather-of-Things（ウェザー・オブ・シングス）データソースとAIによるグローバルな<br/>過去、リアルタイム、予報モデルによる、<br/>ハイパー・ローカルな気象予報＆大気質情報`,
    code: `curl -X GET "https://api.climacell.co/v3/weather/nowcast?lat=-87&lon=-180&timestep=1&unit_system=si&fields=precipitation&start_time=now&end_time=2019-12-19T14%3A09%3A50Z" -H "accept: application/json"`,
    img: img1,
    width: ["100%"]
  },
  {
    title: "ビジネスにパーソナライズされた<br/>気づきを提供",
    text: `チームに関連のあるビジネスの気づきをインサイト・<nobr>ライブラリー</nobr>か独自に設定して提供`,
    code: `curl -X GET "https://api.climacell.co/v3/insights/fire-index?lat=-90&lon=-180" -H "accept: application/json"`,
    img: img2,
    width: [280, 460]
  },
  {
    title: "行動を促し、より良い意思決定をする",
    text: `重要なWebhookアラートを受信し、チームが適切な行動を取れるようにすること。コラボツールで情報共有。`,
    code: `curl -X POST "https://api.climacell.co/v3/alerts" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \\"name\\": \\"Rain alert\\", \\"comment\\": \\"Rain starts is X minutes, alert your team!\\", \\"location_id\\": \\"599b1fe3af38f5039cf2a1e5\\", \\"location_name\\": \\"Headquarters\\", \\"unit_system\\": \\"si\\", \\"notice\\": 60, \\"indicate_if_current_precip\\": true, \\"conditions\\": [ { \\"parameter\\": \\"Rain\\", \\"value\\": 20, \\"accumulated\\": false, \\"operator\\": \\"gt\\", \\"units\\": \\"in\\" } ]}"`,
    img: img3,
    width: [280, 460]
  }
];

const features2 = [
  { text: "開発者の為に開発", icon: code },
  { text: "もっとも正確な気象<nobr>予報</nobr>＆大気質情報", icon: done },
  { text: "テーラードされた<nobr>ビジネス</nobr>インサイト", icon: meter },
  { text: "グローバルデータ", icon: globe },
  { text: "オールインワン<nobr>ソリューション</nobr>", icon: allone },
  { text: "行動とコラボツール", icon: share }
];

export default ({ data, location }) => (
  <Layout bg="#f6f6f6" page="api">
    <SEO
      title="ビジネスに最適な MicroWeather API"
      description="MicroWeather API – the best for your business"
      pathname={location.pathname}
      image={`https://www.tomorrow.io${data.hero.childImageSharp.fluid.src}`}
    />
    <Box overflow="hidden">
      <Container>
        <Box display="flex" flexWrap="wrap">
          <Box
            width={[1, 1 / 2]}
            pt={["2rem", "4rem", "6rem"]}
            pr={[0, "3rem"]}
          >
            <T2 as="h1">
              ビジネスに最適な<br/>
              MicroWeather API
            </T2>
            <Lead as="h2" fontWeight="400" lineHeight={1.5} mb={4}>
              オールインワン気象予報APIへようこそ。<br/>正しい情報を入手、貴重な気づきを提供し、行動を導く。<br/>それがたった数行のコードで完結。
            </Lead>
            <Button
              as="a"
              px={2}
              display={["block", "inline-block"]}
              mb={2}
              id="weather-api-cta-1"
              href="https://www.tomorrow.io/api-signup/"
            >
              無料トライアルを試す <Cursor>&nbsp;</Cursor>
            </Button>{" "}
            <Button
              as="a"
              href='https://Tomorrow.io/weather-api/docs/'
              outline="1"
              px={2}
              display={["block", "inline-block"]}
              width={["100%", "auto"]}
              mb={2}
            >
              資料
            </Button>
          </Box>
          <Box width={[1, 1 / 2]} pt={2}>
            <Img
              fluid={data.hero.childImageSharp.fluid}
              alt="MicroWeather API"
            />
          </Box>
        </Box>
      </Container>

      <Container py={5}>
        <SmBg bottom={0} left={-165}>
          <img src={smbg1} alt=""/>
        </SmBg>
        <Clients/>
      </Container>

      <Box textAlign="center" py={[4, 5]} bg="white">
        <Container>
          <T3 mb={3}>まずは開発者という姿勢</T3>
          <Lead width={[1, 4 / 5]} mx="auto" mb={5}>
            開発者として、弊社ならびに御社にとって、素晴らしいシームレスな導入体験となる気象予報APIを構築しました。私たちのプロダクトは、御社も利用する同様のMicroWeather API によってできています。
            <br/>
            <br/>
            MicroWeather APIエンドポイントを確認
          </Lead>

          <ApiTimeline/>

          <Button
            as="a"
            mt={3}
            mb={2}
            outline="1"
            display={["block", "inline-block"]}
            id="link-docs"
            href="https://Tomorrow.io/weather-api/docs/"
          >
            資料
          </Button>
        </Container>
      </Box>

      <Container>
        <SmBg top={110} left={-160}>
          <img src={smbg2} alt=""/>
        </SmBg>
        <SmBg top={40} right={-150}>
          <img src={smbg3} alt=""/>
        </SmBg>
        <SmBg top={700} left={-70}>
          <img src={smbg4} alt=""/>
        </SmBg>
        <SmBg top={1400} right={-130}>
          <img src={smbg5} alt=""/>
        </SmBg>
        <SmBg top={1650} left={-110}>
          <img src={smbg6} alt=""/>
        </SmBg>
        <SmBg top={2300} right={-130}>
          <img src={smbg7} alt=""/>
        </SmBg>
        <Box py={5} px={[0, 6, 0]}>
          <T3 as="h2" mb={[5, "6rem"]} textAlign="center">
            MicroWeather APIで何ができるのでしょう？
          </T3>
          {features.map((item, i) => {
            let even = (i + 1) % 2 === 0;
            return (
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                key={i}
                mb={5}
              >
                <Box
                  width={[1, 1, 1 / 2]}
                  pr={[0, 0, even ? 0 : 5]}
                  pl={[0, 0, even ? 5 : 0]}
                  order={[1, 1, even ? 2 : 1]}
                >
                  <T4 as="h3" mb={3} dangerouslySetInnerHTML={{ __html: item.title }}/>
                  <Lead dangerouslySetInnerHTML={{ __html: item.text }}/>
                  <Code>
                    <div>{item.code}</div>
                  </Code>
                </Box>
                <Box
                  width={[1, 1, 1 / 2]}
                  order={[2, 2, even ? 1 : 2]}
                  textAlign="center"
                >
                  <Image src={item.img} width={item.width} mx="auto"/>
                </Box>
              </Box>
            );
          })}
          <Box textAlign="center">
            <Button
              as="a"
              mb={2}
              display={["block", "inline-block"]}

              id="weather-api-cta-2"
              href="https://www.tomorrow.io/api-signup/"
            >
              今すぐ試す
            </Button>
          </Box>
        </Box>
      </Container>

      <Box pt={5} bg="white">
        <Container>
          <T3 as="h2" mb={5} textAlign="center" mx="auto" width={[1, 2 / 3]}>
            お客様の声がもっとも信頼のおける評価です
          </T3>
          <LazyLoad height={500} offset={600} once>
            <IndustriesCarouselApi/>
          </LazyLoad>
        </Container>
      </Box>

      <Box py={5} bg="white" textAlign="center" id="free-plans">
        <Container>
          <T3 mb={3}>
            MicroWeatherをすべての人々へ
          </T3>
          <Lead mb={4}>
            アーリーステージのスタートアップ、それとも教育機関ですか？ <br/>
            <Color color={theme.color.primary}>無料特典を用意しています</Color>
          </Lead>
          <Box display="flex" width={[1, 2 / 3]} flexWrap="wrap" mx="auto" justifyContent="center">
            <Box border="1px solid rgba(0,0,0,.05)" py={3} px={5} borderRadius={6} mb={4} mx={3}>
              <Image src={rocket} mb={3} alt=""/>
              <Lead>スタートアッププラン</Lead>
              <Button
                as="a"
                display={["block", "inline-block"]}
                href="https://www.tomorrow.io/weather-api/plan-startups/"
                mb={3}
              >
                もっと詳しく&nbsp;›
              </Button>
            </Box>
            <Box border="1px solid rgba(0,0,0,.05)" py={3} px={5} borderRadius={6} mb={4} mx={3}>
              <Image src={building} mb={3} alt=""/><br/>
              <Lead>教育機関</Lead>
              <Button
                as="a"
                display={["block", "inline-block"]}
                href="https://www.tomorrow.io/weather-api/plan-academia/"
                mb={3}
              >
                もっと詳しく&nbsp;›
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container>
        <SmBg top={-50} left={-165}>
          <img src={smbg1} alt=""/>
        </SmBg>
        <Box textAlign="center" py={5}>
          <T3 mb={3}>どのAPIがあなたのビジネスにフィットするかよく分かりませんか？</T3>
          <Lead width={[1, 2 / 3]} mx="auto">
            <a href="https://www.tomorrow.io/api-ebook/">❝気象予報APIで何が必要か❞が分かる無料ガイドをダウンロード</a>
          </Lead>
          <Box textAlign="center">
            <img width={103} src={book} alt=""/>
          </Box>
        </Box>
      </Container>

      <Box py={5} bg="white">
        <Container>
          <SmBg bottom={-180} right={-240}>
            <img src={smbg8} alt=""/>
          </SmBg>
          <T3 mb={5} textAlign="center">
            MicroWeather APIを選ぶ理由は？
          </T3>
          <ComparingTable/>
        </Container>
      </Box>

      <Container>
        <SmBg bottom={0} left={-160}>
          <img src={smbg9} alt=""/>
        </SmBg>
        <Box width={[1, 2 / 3]} mx="auto" pt={5} display="flex" flexWrap="wrap">
          {features2.map((item, i) => (
            <Box width={[1, 1 / 2, 1 / 3]} mb={5} textAlign="center" key={i}>
              <Image mb={2} src={item.icon} alt=""/>
              <Box mx="auto" width={[1, 2 / 3]} dangerouslySetInnerHTML={{__html: item.text}}/>
            </Box>
          ))}
        </Box>
        <Box textAlign="center" pb={6}>
          <Button
            as="a"
            display={["block", "inline-block"]}

            id="weather-api-cta-3"
            href="https://www.tomorrow.io/api-signup/"
          >
            無料トライアルをスタート
          </Button>
        </Box>
      </Container>
    </Box>
  </Layout>
);

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "api-hero.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Code = styled.div`
  position: relative;
  margin-bottom: 1rem;
  @media screen and (min-width: 1023px) {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 20px;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
      );
    }
  }
  > div {
    padding: 1rem;
    background-color: white;
    font-size: 1rem;
    font-family: monospace;
    overflow-wrap: break-word;
    border-radius: 5px;
    @media screen and (min-width: 1023px) {
      max-height: 110px;
      overflow-y: auto;
    }
  }
`;

const SmBg = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    position: absolute;
    top: ${props => props.top && `${props.top}px`};
    bottom: ${props => props.bottom && `${props.bottom}px`};
    right: ${props => props.right && `${props.right}px`};
    left: ${props => props.left && `${props.left}px`};
  }
`;

const Cursor = styled.span`
  display: inline-block;
  vertical-align: middle;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAA4CAYAAACYCio/AAAAAXNSR0IArs4c6QAAArVJREFUWAnFmE1IVGEUhv3JNMtExEKKsOgHS6EMQhdhm3BR5CawVbgLl+VSXIpLDTdhrapVBS4qIgqEiKCfRREkEgVZVJRYFEX/03PAgcvlTJ6B+04HDvPddw7nfeabxXe+W1ZWIHK53CbyUIGvSyMDsI18Rf4gD5bGNeWCcTv5lszHNxb7U2XaRwx3k/N5gsTnF9Z7te6L3THqIj8mzNPLTwh7pDAY2N/xOe3sPC+g7ZTB0HwZOeUYe9I7xFYlzHIMrnnOjvYabbMSZgUG046xJ71A3KCEWYXBHc/Z0Z6iNSth6jF44Bh70hPEJiVMIwaPPWdHe4jWoIRZi8GsY+xJdxHrlDDrMXjuOTvaLbRaJcxGDF46xp50A7FaCbMVg+Rh6EHktcssqpQwbRh4h2IeIPl5gYdKJUwHBv86HJMwZ3koV8LYSR05JA3qlAzEGmOwj/xqToEYU8P0APE9AGIlI2qYXkx+BmGG1DBHAPkdhDmuhukH5E8Q5pgaZiAIYsBH1TCDQZhf1B1WwwwHYewCd0AGQ/Nm0ib+SFyXgODcRNrUFol7FGU/w9C0gbRpLRKPKMp+qqNpHWlTWiRmKFqT+V9C01rSprNIPKNonQKimsY2lUVijqIWBUQVjW0ai8QbirYoICppfDFCQM17cocCooLG54IQH6jblTmENaTxZBDCprhOFcR4EMKmt24VxGgQwqa2HhVE9CCzaa1XBXEiuBM2pfWpIIoZdvpVEMWMfwMqiD622bY6EoMqiGKuCMMqiGIuTaMqiG7+h+g18qQKohOI6MX6NLXZ3/Jpup2Mvmo4T22FajdqaH6FXCouUaB7+WK/DgMbcsyoUFzlC93rqOQWY2TDjm19Om4i1CRr5WsMbeg5kyC5zXql3NgzwLicnCDvk6u9mpJqQPyfnSjpr0yZ/QXodkjLXJuPcgAAAABJRU5ErkJggg==");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  margin-right: 0.9rem;
  position: relative;
  &:after {
    content: "";
    width: 0.65rem;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.3);
    position: absolute;
    left: 0.9rem;
    bottom: 0;
    animation: blink 1.2s infinite;
  }

  @keyframes blink {
    0% {
      border-bottom-color: hsla(0, 0%, 100%, 0.8);
    }
    49% {
      border-bottom-color: hsla(0, 0%, 100%, 0.8);
    }
    50% {
      border-bottom-color: hsla(0, 0%, 100%, 0.3);
    }
  }
`;
