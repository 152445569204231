import React, { useState } from "react";
import styled from "styled-components";
import { Box, Image, Lead } from "../styles/Styles";
import theme from "../styles/theme";
import bookHex from "../img/icon-hex-book.svg";
import bookHexBlue from "../img/icon-hex-book-blue.svg";
import arrowDown from "../img/icon-hex-arrow-down.svg";
import arrowDownBlue from "../img/icon-hex-arrow-down-blue.svg";
import timer from "../img/icon-hex-timer.svg";
import timerBlue from "../img/icon-hex-timer-blue.svg";
import clock from "../img/icon-hex-clock.svg";
import clockBlue from "../img/icon-hex-clock-blue.svg";
import calendar from "../img/icon-hex-calendar.svg";
import calendarBlue from "../img/icon-hex-calendar-blue.svg";
import arrow from "../img/arrow-api-timeline.svg";
import car from "../img/icon-hex-car.svg";
import cal from "../img/icon-hex-cal.svg";
import vent from "../img/icon-hex-vent.svg";
import wind from "../img/icon-hex-wind.svg";
import globe from "../img/icon-hex-globe.svg";
import temp from "../img/icon-hex-temp.svg";
import rain from "../img/icon-hex-rain.svg";
import plus from "../img/icon-hex-plus.svg";
import time from "../img/icon-hex-time.svg";
import fire from "../img/icon-hex-fire.svg";

const timeline = [
  {
    title: "過去",
    sub: "(-4週間)",
    icon: bookHex,
    iconActive: bookHexBlue,
    pos: "left",
    features: [
      {
        icon: cal,
        text: "4週間分の時間毎のデータ"
      },
      {
        icon: time,
        text: "6時間分の分単位のデータ"
      },
      {
        icon: rain,
        text: "降水量タイプ＆激しさ"
      },
      {
        icon: temp,
        text: "気温"
      },
      {
        icon: wind,
        text: "風速＆方向"
      },
      {
        icon: vent,
        text: "グローバル大気質汚染"
      },
      {
        icon: car,
        text: "道路リスクスコア"
      }
    ]
  },
  {
    title: "リアルタイム",
    sub: "現在",
    icon: arrowDown,
    iconActive: arrowDownBlue,
    pos: "center",
    features: [
      {
        icon: globe,
        text: "グローバル気象予報データ"
      },
      {
        icon: vent,
        text: "グローバル大気質汚染"
      },
      {
        icon: rain,
        text: "降水量タイプ＆激しさ"
      },
      {
        icon: temp,
        text: "気温"
      },
      {
        icon: wind,
        text: "風速＆方向"
      },
      {
        icon: car,
        text: "道路リスクスコア"
      }
    ]
  },
  {
    title: "ナウキャスティング",
    sub: "(+6時間)",
    icon: timer,
    iconActive: timerBlue,
    pos: "center",
    features: [
      {
        icon: time,
        text: "分単位の気象予報データ"
      },
      {
        icon: vent,
        text: "グローバル大気質汚染"
      },
      {
        icon: rain,
        text: "降水量タイプ＆激しさ"
      },
      {
        icon: temp,
        text: "気温"
      },
      {
        icon: wind,
        text: "風速＆方向"
      },
      {
        icon: fire,
        text: "火災インデックス"
      },
      {
        icon: car,
        text: "道路リスクスコア"
      }
    ]
  },
  {
    title: "時間毎",
    sub: "(+96時間)",
    icon: clock,
    iconActive: clockBlue,
    pos: "center",
    features: [
      {
        icon: globe,
        text: "グローバル気象予報データ"
      },
      {
        icon: vent,
        text: "グローバル大気質汚染"
      },
      {
        icon: rain,
        text: "降水量タイプ＆激しさ"
      },
      {
        icon: temp,
        text: "気温"
      },
      {
        icon: wind,
        text: "風速＆方向"
      },
      {
        icon: car,
        text: "道路リスクスコア"
      }
    ]
  },
  {
    title: "毎日",
    sub: "(+15日)",
    icon: calendar,
    iconActive: calendarBlue,
    pos: "right",
    features: [
      {
        icon: globe,
        text: "グローバル気象予報データ"
      },
      {
        icon: rain,
        text: "降水量タイプ＆激しさ"
      },
      {
        icon: temp,
        text: "気温"
      },
      {
        icon: wind,
        text: "風速＆方向"
      }
    ]
  }
];

const ApiTimeline = () => {
  const [selected, setSelected] = useState(1);

  return (
    <Wrap>
      {timeline.map((item, i) => {
        return (
          <Item width={[1, 1 / 2, 1 / 5]} px={[3]} key={item.title}>
            <Box mb={[2, 2, 5]} onClick={() => setSelected(i)}>
              <img
                width={83}
                src={i === selected ? item.iconActive : item.icon}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </Box>
            <TitleWrap onClick={() => setSelected(i)} selected={i === selected}>
              <Lead as="strong">{item.title}</Lead>
              <Box mb={3}>{item.sub}</Box>
            </TitleWrap>
            <Panel selected={i === selected}>
              <div className={item.pos === "center" ? "" : (item.pos === "left" ? "left" : "right")}>
                {item.features.map((feature, n) => (
                  <Box key={n} mb={2}>
                    <Image width={26} mr={2} src={feature.icon} alt=""/>
                    {feature.text}
                  </Box>
                ))}
                <Box key={"10x"}>
                  <Image width={26} mr={2} src={plus} alt=""/>{" "}その他・・・
                </Box>
              </div>
            </Panel>
          </Item>
        );
      })}
    </Wrap>
  );
};

export default ApiTimeline;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  @media only screen and (min-width: 1024px) {
    &:after {
      content: "";
      position: absolute;
      top: 122px;
      left: 50%;
      transform: translateX(-50%);
      width: 950px;
      height: 8px;
      background-color: #e7e7e7;
    }
  }
`;

const Item = styled(Box)`
  position: relative;
  transition: all 0.3s;
  @media only screen and (min-width: 1024px) {
    &:after {
      content: "";
      position: absolute;
      top: 109px;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 13px;
      background-color: #e7e7e7;
    }
  }
`;

const TitleWrap = styled.div`
  cursor: pointer;
  color: ${props => (props.selected ? theme.color.primary : "inherit")};
`;

const Panel = styled.div`
  position: relative;
  min-height: 180px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  > div {
      font-size: 0.9rem;
      margin-bottom: 2rem;
      text-align: left;
      width: 100%;
      max-width: 320px;
      margin-right: auto;
      margin-left: auto;
      border: 1px solid #E0EEFF;
      border-radius: 4px; 
      padding: 1rem;
    @media only screen and (min-width: 1024px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      max-width: unset;
      width: 600px;
      opacity: ${props => (props.selected ? 1 : 0)};
      border-radius: 4px; 
      background-color: #E0EEFF;
      column-count: 2;
      :after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 2px;
        background-color: white;
        border: 1px solid #E0EEFF;
        border-radius: 4px; 
      }
      :before {
        content: '';
        position: absolute;
        background: url(${arrow}) no-repeat center / contain;
        top: -13px;
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        display: block; 
      }
      &.left {
        left: 1rem;
        transform: translateX(0);
        :before {
          left: 75px;
        }
      }
      &.right {
        left: auto;
        right: 1rem;
        transform: translateX(0);
        :before {
          left: auto;
          right: 75px;
        }
      } 
    }
  }
`;