import React from "react";
import iconClimacell from "../img/icon-climacell-table.svg";
import iconOthers from "../img/icon-others-table.svg";

const table = [
  {
    parameter: "データ解像度",
    climacell: `<span class="d-md-none">tomorrow.io:</span> 300-500m (道路レベル)`,
    others: `<span class="d-md-none">他機関:</span> 2000-4000m`,
  },
  {
    parameter: "インプット更新時間",
    climacell: `<span class="d-md-none">tomorrow.io:</span> 1分`,
    others: `<span class="d-md-none">他機関:</span> 5-30分`,
  },
  {
    parameter: "データソース",
    climacell: `<span class="d-md-none">tomorrow.io:</span> ウェザー・オブ・シングス、Nexrad、衛星、気象観測所`,
    others: `<span class="d-md-none">他機関:</span> 通常はNexrad`,
  },
  {
    parameter: "地上の実気象との相関性",
    climacell: `<span class="d-md-none">tomorrow.io:</span> >90%`,
    others: `<span class="d-md-none">他機関:</span> ~50%`,
  },
  {
    parameter: "アウトプット更新時間",
    climacell: `<span class="d-md-none">tomorrow.io:</span> 1分`,
    others: `<span class="d-md-none">他機関:</span> 60分以内`,
  },
  {
    parameter: "データの観測地",
    climacell: `<span class="d-md-none">tomorrow.io:</span> 地上レベル`,
    others: `<span class="d-md-none">他機関:</span> 雲レベル`,
  },
  {
    parameter: "地図レイヤ",
    climacell: `<span class="d-md-none">tomorrow.io:</span> V`,
    others: `<span class="d-md-none">他機関:</span> X`,
  },
  {
    parameter: "グローバルデータ",
    climacell: `<span class="d-md-none">tomorrow.io:</span> V`,
    others: `<span class="d-md-none">他機関:</span> X`,
  },
];

const ComparingTable = () => {
  return (
    <div className="row justify-content-center mx-0">
      <div className="col-md-9">
        <div className="row no-gutters c-table bg-light">
          <div className="offset-md-4 col-md-4 py-3 d-none d-md-flex justify-content-center align-items-center text-center fw-800">
            <img width={22} className="mr-2" src={iconClimacell} alt="" />
            tomorrow.io
          </div>
          <div className="col-md-4 py-3 d-none d-md-flex justify-content-center align-items-center text-center fw-800">
            <img width={22} className="mr-2" src={iconOthers} alt="" /> 他機関
          </div>
        </div>
        {table.map((item, index) => (
          <div className="row no-gutters c-table" key={index}>
            <div
              className="col-md-4 c-table-col fw-800"
              dangerouslySetInnerHTML={{ __html: item.parameter }}
            />
            <div
              className="col-md-4 c-table-col text-primary fw-800"
              dangerouslySetInnerHTML={{ __html: item.climacell }}
            />
            <div
              className="col-md-4 c-table-col mb-4 mb-md-0"
              dangerouslySetInnerHTML={{ __html: item.others }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparingTable;
